import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

const RiveIndicator = () => {
  const STATE_MACHINE_NAME = "State Machine 1";
  const PROGRESS_NAME = "Progress";
  const WOBBLE_NAME = "Pressed";

  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "rive/indicator.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const OnClickInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    PROGRESS_NAME
  );

  const WobbleInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    WOBBLE_NAME
  );

  const OnClick = () => {
    OnClickInput.value += 1;
    WobbleInput.fire();
  };

  // check what's inside
  // if (rive) {
  //   console.log(rive.contents);
  // }

  return <RiveComponent onClick={() => OnClick()} />;
};

export default RiveIndicator;
