import React from "react";
import RiveIndicator from "./components/Indicator/Indicator.js"; // Importing your Rive button component
import CoinCounter from "./components/CoinCounter/CoinCounter.js";
import "./App.css"; // Styles for gradient background and other UI components
import Name from "./components/Name/Name.js";
import RiveBook from "./components/Book/Book.js";

function App() {
  return (
    <div className="app-container">
      {/* Gradient background */}
      <div className="background">
        {/* Top Section: Animal change button and Menu */}
        <div className="top-section">
          <RiveBook />
          <CoinCounter coins={10000} />
          <img className="menu-button" src="images/menu.svg" alt="Menu" />
        </div>

        {/* Main character section */}
        <div className="character-section">
          {/* Coin Counter component */}
          {/* <CoinCounter coins={coins} /> */}
          <Name name="Пушистик" />

          <div className="character">
            {/* Placeholder for character, replace with your character element */}
            <img src="images/animal.svg" alt="Character" />
          </div>
        </div>

        {/* Bottom Section: 4 Buttons with RiveIndicator */}
        <div className="bottom-buttons">
          <div className="RiveButton">
            <RiveIndicator />
          </div>
          <div className="RiveButton">
            <RiveIndicator />
          </div>
          <div className="RiveButton">
            <RiveIndicator />
          </div>
          <div className="RiveButton">
            <RiveIndicator />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
